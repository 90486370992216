import { extendTheme } from "@chakra-ui/react"

export const theme1 = extendTheme({
  colors: {
    yellow: {
      50: "#FFF7E5",
      100: "#FFE7B8",
      200: "#FFD88A",
      300: "#FFC95C",
      400: "#FFBA2E",
      500: "#FFAA00",
      600: "#CC8800",
      700: "#996600",
      800: "#664400",
      900: "#332200",
    },
    blue: {
      50: "#ECF0F9",
      100: "#C9D5EE",
      200: "#A6BAE3",
      300: "#839FD8",
      400: "#6084CC",
      500: "#3E69C1",
      600: "#31549B",
      700: "#253F74",
      800: "#192A4D",
      900: "#0C1527",
    },
    red: {
      50: "#FBECE9",
      100: "#F5C9C2",
      200: "#EFA79A",
      300: "#E88473",
      400: "#E2614B",
      500: "#DB3F24",
      600: "#AF321D",
      700: "#842615",
      800: "#58190E",
      900: "#2C0D07",
    },
  },
})

export const theme2 = extendTheme({
  colors: {
    yellow: {
      50: "#FFF7E5",
      100: "#FFE7B8",
      200: "#FFD88A",
      300: "#FFC95C",
      400: "#FFBA2E",
      500: "#FFAA00",
      600: "#CC8800",
      700: "#996600",
      800: "#664400",
      900: "#332200",
    },
    blue: {
      50: "#E5F2FF",
      100: "#B8DBFF",
      200: "#8AC3FF",
      300: "#5CACFF",
      400: "#2E95FF",
      500: "#007DFF",
      600: "#0064CC",
      700: "#004B99",
      800: "#003266",
      900: "#001933",
    },
    red: {
      50: "#FBECE9",
      100: "#F5C9C2",
      200: "#EFA79A",
      300: "#E88473",
      400: "#E2614B",
      500: "#DB3F24",
      600: "#AF321D",
      700: "#842615",
      800: "#58190E",
      900: "#2C0D07",
    },
  },
})

export const theme3 = extendTheme({
  // set styles for global elements
  styles: {
    global: {
      // styles for the `body`
      h1: {
        display: "block",
        fontSize: "2em",
        marginBlockStart: "0.67em",
        marginBlockEnd: "0.67em",
        marginInlineStart: "0px",
        marginInlineEnd: "0px",
        fontWeight: "bold",
      },
      h2: {
        display: "block",
        fontSize: "1.5em",
        marginBlockStart: "0.83em",
        marginBlockEnd: "0.83em",
        marginInlineStart: "0px",
        marginInlineEnd: "0px",
        fontWeight: "bold",
      },
      h3: {
        display: "block",
        fontSize: "1.17em",
        marginBlockStart: "1em",
        marginBlockEnd: "1em",
        marginInlineStart: "0px",
        marginInlineEnd: "0px",
        fontWeight: "bold",
      },
      h4: {
        display: "block",
        fontSize: "1em",
        marginBlockStart: "1.33em",
        marginBlockEnd: "1.33em",
        marginInlineStart: "0px",
        marginInlineEnd: "0px",
        fontWeight: "bold",
      },
      h5: {
        display: "block",
        fontSize: "0.83em",
        marginBlockStart: "1.67em",
        marginBlockEnd: "1.67em",
        marginInlineStart: "0px",
        marginInlineEnd: "0px",
        fontWeight: "bold",
      },
    },
  },

  colors: {
    yellow: {
      50: "#FFF7E5",
      100: "#FFE7B8",
      200: "#FFD88A",
      300: "#FFC95C",
      400: "#FFBA2E",
      500: "#FFAA00",
      600: "#CC8800",
      700: "#996600",
      800: "#664400",
      900: "#332200",
    },
    blue: {
      50: "#E5F2FF",
      100: "#B8DBFF",
      200: "#8AC3FF",
      300: "#5CACFF",
      400: "#2E95FF",
      500: "#007DFF",
      600: "#0064CC",
      700: "#004B99",
      800: "#003266",
      900: "#001933",
    },
    // red: {
    //   50: "#FBECE9",
    //   100: "#F5C9C2",
    //   200: "#EFA79A",
    //   300: "#E88473",
    //   400: "#E2614B",
    //   500: "#DB3F24",
    //   600: "#AF321D",
    //   700: "#842615",
    //   800: "#58190E",
    //   900: "#2C0D07",
    // },
    red: {
      50: "#EAECFA",
      100: "#C5CAF1",
      200: "#A0A7E8",
      300: "#7B85DF",
      400: "#5663D6",
      500: "#3141CE",
      600: "#2834A4",
      700: "#1E277B",
      800: "#141A52",
      900: "#0A0D29",
    },
    purple: {
      50: "#EAECFA",
      100: "#C5CAF1",
      200: "#A0A7E8",
      300: "#7B85DF",
      400: "#5663D6",
      500: "#3141CE",
      600: "#2834A4",
      700: "#1E277B",
      800: "#141A52",
      900: "#0A0D29",
    },
    pink: {
      50: "#F6EEF6",
      100: "#E6D1E6",
      200: "#D6B3D5",
      300: "#C695C5",
      400: "#B677B4",
      500: "#A55AA4",
      600: "#844883",
      700: "#633662",
      800: "#422442",
      900: "#211221",
    },
    gray: {
      50: "#EEF1F6",
      100: "#D0D9E7",
      200: "#B1C0D7",
      300: "#93A8C8",
      400: "#748FB8",
      500: "#5677A9",
      600: "#455F87",
      700: "#344765",
      800: "#222F44",
      900: "#111822",
    },
  },
  // h1: {
  //   display: "block",
  //   fontSize: "2em",
  //   marginBlockStart: "0.67em",
  //   marginBlockEnd: "0.67em",
  //   marginInlineStart: "0px",
  //   marginInlineEnd: "0px",
  //   fontWeight: "bold",
  // },
  // h2: {
  //   display: "block",
  //   fontSize: "1.5em",
  //   marginBlockStart: "0.83em",
  //   marginBlockEnd: "0.83em",
  //   marginInlineStart: "0px",
  //   marginInlineEnd: "0px",
  //   fontWeight: "bold",
  // },
  // h3: {
  //   display: "block",
  //   fontSize: "1.17em",
  //   marginBlockStart: "1em",
  //   marginBlockEnd: "1em",
  //   marginInlineStart: "0px",
  //   marginInlineEnd: "0px",
  //   fontWeight: "bold",
  // },
  // h4: {
  //   display: "block",
  //   fontSize: "1em",
  //   marginBlockStart: "1.33em",
  //   marginBlockEnd: "1.33em",
  //   marginInlineStart: "0px",
  //   marginInlineEnd: "0px",
  //   fontWeight: "bold",
  // },
  // h5: {
  //   display: "block",
  //   fontSize: "0.83em",
  //   marginBlockStart: "1.67em",
  //   marginBlockEnd: "1.67em",
  //   marginInlineStart: "0px",
  //   marginInlineEnd: "0px",
  //   fontWeight: "bold",
  // },
})

export default theme3; // Dodajemy eksport domyślny
