// src/components/LanguageProvider.js
import React, { createContext, useState, useEffect } from "react";

export const LanguageContext = createContext({
  locale: "en",
  toggleLanguage: () => {},
});

const LanguageProvider = ({ children }) => {
  const getLocaleFromUrl = () => {
    if (typeof window === "undefined") return "en";
    const pathLocale = window.location.pathname.split("/")[1];
    return ["pl", "en"].includes(pathLocale) ? pathLocale : "en";
  };

  const [locale, setLocale] = useState(getLocaleFromUrl);

  const toggleLanguage = () => {
    const newLocale = locale === "pl" ? "en" : "pl";
    setLocale(newLocale);
    const newPath = `/${newLocale}${window.location.pathname.replace(/^\/(pl|en)/, "")}`;
    window.location.assign(newPath); // Wymuszenie zmiany URL bez przekierowania z serwera
  };

  useEffect(() => {
    setLocale(getLocaleFromUrl());
  }, []);

  return (
    <LanguageContext.Provider value={{ locale, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
