import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useIntl } from "react-intl"; // Zamieniono na `useIntl`

const CookieConsent = () => {
  const intl = useIntl(); // Użycie `useIntl` hook
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get("cookieConsent");
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set("cookieConsent", "true", { expires: 365 });
    setIsVisible(false);
  };

  const handleReject = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "#fff",
        color: "#333",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        zIndex: 9999,
        maxWidth: "400px",
        textAlign: "center",
      }}
    >
      <p style={{ marginBottom: "16px" }}>
        {intl.formatMessage({ id: "cookieConsent.message" })}
      </p>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <button
          style={{
            backgroundColor: "#805ad5",
            color: "#fff",
            padding: "10px 20px",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            marginRight: "10px",
          }}
          onClick={handleAccept}
        >
          {intl.formatMessage({ id: "cookieConsent.accept" })}
        </button>
        <button
          style={{
            backgroundColor: "#fff",
            color: "#555",
            padding: "10px 20px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={handleReject}
        >
          {intl.formatMessage({ id: "cookieConsent.reject" })}
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
