// gatsby-browser.js
import React from "react";
import CookieConsent from "./src/components/CookieConsent";
import { IntlProvider } from "react-intl";
import LanguageProvider, { LanguageContext } from "./src/components/LanguageProvider";
import { ChakraProvider } from "@chakra-ui/react";
import theme3 from "./src/components/theme";
import { messages } from "./src/intl/config"; // Importujemy tłumaczenia

export const wrapRootElement = ({ element }) => (
  <LanguageProvider>
    <LanguageContext.Consumer>
      {({ locale }) => {
        const translations = messages[locale] || messages["pl"]; // Korzystamy z kontekstu `locale`

        return (
          <IntlProvider locale={locale} messages={translations}>
            <ChakraProvider theme={theme3}>
              {element}
              <CookieConsent />
            </ChakraProvider>
          </IntlProvider>
        );
      }}
    </LanguageContext.Consumer>
  </LanguageProvider>
);
