// src/intl/config.js
export const messages = {
  en: require("./en.json"),
  pl: require("./pl.json"),
};

export const getUserLocale = () => {
  const storedLocale = window.localStorage.getItem("locale");
  if (storedLocale) return storedLocale;

  const navigatorLocale = navigator.language.split("-")[0];
  return Object.keys(messages).includes(navigatorLocale) ? navigatorLocale : DEFAULT_LOCALE;
};
